import React, { FC } from 'react';
import { Link, graphql } from 'gatsby';
import Image from 'common/Image';
import Col from 'react-bootstrap/Col';
import { BuyNowItemProps } from './models';

import './BuyNowItem.scss';

const BuyNowItem: FC<BuyNowItemProps> = ({ imgAlt, link, logoImg }) => (
  <Col data-testid="buy-now-item" xs="12" sm="6" lg="3">
    <div className="buy-now-item">
      <Link to={link?.[0]?.url} target="_blank" className="buy-now-item__link">
        <Image imageData={logoImg} alt={imgAlt} />
      </Link>
    </div>
  </Col>
);

export const query = graphql`
  fragment BuyNowItemFragment on ResellerItemProps {
    imgAlt
    link {
      name
      url
    }
    logoImg {
      ...ImageFragment
    }
  }
`;

export default BuyNowItem;

import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import 'styles/main.scss';
import './BuyNowPage.scss';

import createBreadcrumbs from 'utils/createBreadcrumbs';
import Layout from 'layout/Layout';
import SectionTitle from 'common/SectionTitle';
import BuyNowItem from 'components/BuyNowItem';
import { BuyNowPageProps } from './models';

const BuyNowPage: FC<BuyNowPageProps> = ({
  data: {
    buyNow: { title, resellersList, seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoNoIndex },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);

  return (
    <Layout
      headerTransparent
      breadcrumbs={breadcrumbs}
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoNoIndex }}
    >
      <Container className="buy-now-page page">
        <Row>
          <Col>
            <SectionTitle text={title} isMainTitle />
          </Col>
        </Row>
        <Row className="buy-now-page__list">
          {resellersList.map(({ properties }) => (
            <BuyNowItem key={properties.imgAlt} {...properties} />
          ))}
        </Row>
      </Container>
    </Layout>
  );
};
export const query = graphql`
  {
    allSitePage {
      ...FragmentAllPageSite
    }
    buyNow {
      url
      name
      title
      resellersList {
        properties {
          ...BuyNowItemFragment
        }
      }
      ...SeoFragment
    }
  }
`;

export default BuyNowPage;
